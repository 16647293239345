export const currentQuestDataVersion = '31';
export const dateOfQuestInfo = '01/31/2025';
export const latestPatchNotes = {
  31: ['Activated last of this wipes quests(?)'],
  30: ['New wipe quests'],
  29: ['New wipe quests'],
  28: ['New wipe quests'],
  27: ['New wipe quests'],
  26: ['New wipe quests'],
  25: ['New wipe quests'],
  24: ['Updated quests'],
  23: ['Updated quests'],
  22: ['Updated quests'],
  21: ['Added Character Selection', 'New wipe Quests', 'Added Ruins map'],
  20: ['Added Character Selection', 'New wipe Quests', 'Added Ruins map'],
  19: ['Added Character Selection', 'New wipe Quests', 'Added Ruins map'],
  18: ['Added Character Selection', 'New wipe Quests', 'Added Ruins map'],
  17: ['Added Character Selection', 'New wipe Quests', 'Added Ruins map'],
  16: [
    'Updated Map Page UI',
    'Added Active Quests to map page',
    'Fixed various map related bugs',
    'Awesome New Icons! Shoutout FunnyKerk!',
  ],
  15: [
    'Added ability to scale map icons',
    'Stabilized map loading',
    'Added Map filters for quests!',
    'Added screenshots for explore quest locations',
  ],
  14: [
    'Added Map filters for quests!',
    'Added screenshots for explore quest locations',
  ],
  13: [
    'Added Map filters for quests!',
    'Added screenshots for explore quest locations',
  ],
  12: ['Added Map filters for quests!'],
  11: ['New Map Icons!'],
  10: ['New waves of quests are marked as available', 'New Map page!'],
  9: ['New waves of quests are marked as available', 'New Map page!'],
  8: ['Updated minor quest details'],
  7: ['Updated quests for Early Access Season #4!'],
  6: ['Updated quests for Early Access Season #4!'],
  5: ['Updated quests for Early Access Season #4!'],
  4: ['Added quests for Hotifix #42'],
  3: ['Added new Quests'],
  2: [
    'Fixed Quest counts',
    'Added new quests',
    'Added Quest Items Page',
    'Added images to collect item quest objectives',
  ],
};
